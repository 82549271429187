<template>
  <b-container fluid>
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h4 class="mr-2">Edit Inventory Item</h4>
      </div>
      <div class="d-flex align-items-center my-1">
        <b-button variant="primary" @click="submitForm"> Save </b-button>
      </div>
    </div>
    <ValidationObserver ref="formObserver">
      <b-card>
        <b-row>
          <!-- First Column -->
          <b-col cols="6">
            <!-- Product Name -->
            <ValidationProvider
              name="Product Name"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Product Name">
                <b-form-input
                  v-model="productName"
                  placeholder="Enter Product Name"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Select Product Category -->
            <ValidationProvider
              name="Product Category"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Select Product Category">
                <v-select
                  v-model="product_category_id"
                  :options="productCategories"
                  label="category_name"
                  :reduce="(category) => category.id"
                  placeholder="Select a Product Category"
                  class="v-select"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Selling Price -->
            <ValidationProvider
              name="Selling Price"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Selling Price">
                <b-form-input
                  v-model="sellingPrice"
                  placeholder="Selling Price"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Cost Price -->
            <ValidationProvider
              name="Cost Price"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Cost Price">
                <b-form-input
                  v-model="costPrice"
                  placeholder="Cost Price"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Quantity in Stock -->
            <ValidationProvider
              name="Quantity in Stock"
              rules="required|integer"
              v-slot="{ errors }"
            >
              <b-form-group label="Quantity in Stock">
                <b-form-input
                  v-model="quantityInStock"
                  type="number"
                  placeholder="Enter Quantity in Stock"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Warehouse Location -->
            <ValidationProvider
              name="Warehouse Location"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Warehouse Location">
                <v-select
                  v-model="warehouse_id"
                  :options="branches"
                  label="warehouse"
                  placeholder="Select a Warehouse Location"
                  class="v-select"
                  multiple
                  :reduce="(branch) => branch.id"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <!-- Discount -->
            <b-form-group label="Discount">
              <b-row>
                <b-col cols="9">
                  <b-form-input
                    v-model="discount"
                    placeholder="Enter Discount"
                    :disabled="!addDiscount"
                  ></b-form-input>
                </b-col>
                <b-col cols="3" class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="addDiscount"
                    switch
                  ></b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-group>

            <!-- Expiry Date -->
            <b-form-group label="Expiry Date">
              <b-row>
                <b-col cols="9">
                  <flat-pickr
                    v-model="expiryDate"
                    class="form-control"
                    placeholder="Select Expiry Date"
                    :disabled="!addExpiryDate"
                  ></flat-pickr>
                </b-col>
                <b-col cols="3" class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="addExpiryDate"
                    switch
                  ></b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <!-- Second Column -->
          <b-col cols="6">
            <!-- Short Description -->
            <b-form-group label="Short Description">
              <b-form-input
                v-model="shortDescription"
                placeholder="Enter Short Description"
              ></b-form-input>
            </b-form-group>

            <!-- Product Long Description -->
            <b-form-group label="Product Long Description">
              <b-form-textarea
                v-model="longDescription"
                placeholder="Enter Product Long Description"
              ></b-form-textarea>
            </b-form-group>

            <!-- Return Policy -->
            <b-form-group label="Return Policy">
              <b-row>
                <b-col cols="9">
                  <b-form-textarea
                    v-model="returnPolicy"
                    placeholder="Enter Return Policy"
                    :disabled="!addReturnPolicy"
                  ></b-form-textarea>
                </b-col>
                <b-col cols="3" class="d-flex align-items-center">
                  <b-form-checkbox
                    v-model="addReturnPolicy"
                    switch
                  ></b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-group>

            <!-- Date Added -->
            <b-form-group label="Date Added">
              <flat-pickr
                v-model="dateAdded"
                class="form-control"
                placeholder="Select Date and Time"
              ></flat-pickr>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-form-group label="Upload Images">
          <b-form-file
            v-model="images"
            accept="image/*"
            multiple
            placeholder="Choose images..."
            drop-placeholder="Drop images here..."
            @change="onImageChange"
          ></b-form-file>
        </b-form-group>
        <div v-if="imageUrls.length" class="d-flex flex-wrap">
          <div
            v-for="(image, index) in imageUrls"
            :key="index"
            class="position-relative m-2"
            style="width: 300px; height: 300px"
          >
            <b-img
              :src="image.url"
              fluid
              alt="Uploaded Image"
              style="width: 100%; height: 100%; object-fit: cover"
            ></b-img>
            <div
              class="position-absolute"
              style="
                top: 5px;
                right: 5px;
                background-color: white;
                border-radius: 50%;
              "
            >
              <b-button
                variant="danger"
                class="p-1"
                style="
                  font-size: 0.75rem;
                  width: 24px;
                  height: 24px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                @click="deleteImage(index)"
              >
                <span style="font-size: 1rem">&times;</span>
              </b-button>
            </div>
          </div>
        </div>
      </b-card>
    </ValidationObserver>
  </b-container>
</template>
  
  <script>
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, numeric, integer } from "@validations";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    MyTable,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    FeatherIcon,
  },

  props: ["id"], // Receive the id prop

  data() {
    return {
      productName: "",
      product_category_id: "", // Initialize to null
      productCategories: [], // Populate this with your categories
      branches: [],
      sellingPrice: "",
      costPrice: "",
      quantityInStock: "",
      warehouse_id: "",
      discount: "",
      addDiscount: false,
      expiryDate: null,
      addExpiryDate: false,
      shortDescription: "",
      longDescription: "",
      returnPolicy: "",
      addReturnPolicy: false,
      dateAdded: null,
      required,
      images: [],
      imageUrls: [],
      deleteImages: [],
    };
  },

  created() {
    this.fetchProductCategories();
    this.fetchBranches();
    this.fetchProductDetails();
  },

  methods: {
    async fetchProductCategories() {
      try {
        const response = await this.$http.get(
          "inventory/admin/get_product_categories"
        );
        if (response.status === 200 && response.data.data?.length > 0) {
          this.productCategories = response.data.data;
        } else {
          this.productCategories = [];
        }
      } catch (error) {
        console.error("Error fetching product categories:", error);
        this.productCategories = [];
      }
    },

    async fetchBranches() {
      try {
        const response = await this.$http.get(
          "inventory/admin/get_all_warehouses"
        );
        if (response.status === 200 && response.data.data?.length > 0) {
          this.branches = response.data.data;
        } else {
          this.branches = [];
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
        this.branches = [];
      }
    },

    async fetchProductDetails() {
      try {
        const response = await this.$http.get(
          `inventory/admin/products/${this.id}`
        );
        if (response.status === 200 && response.data) {
          const product = response.data.data;
          this.productName = product.product_name;
          if (Array.isArray(this.productCategories)) {
            let matchedCategory = this.productCategories.find(
              (category) => category.category_name === product.product_category
            );
            this.product_category_id = matchedCategory
              ? matchedCategory.id
              : product.product_category;
          } else {
            this.product_category_id = product.product_category;
          }
          this.sellingPrice = product.selling_price;
          this.costPrice = product.cost_price;
          this.quantityInStock = product.remaining_stock;
          this.warehouse_id = product.warehouse;
          this.discount = product.discount;
          this.addDiscount = !!product.discount;
          this.expiryDate = product.expiry_date;
          this.addExpiryDate = !!product.expiry_date;
          this.shortDescription = product.short_description;
          this.longDescription = product.long_description;
          this.returnPolicy = product.return_policy;
          this.addReturnPolicy = !!product.return_policy;
          this.dateAdded = product.date_added;
          this.imageUrls = product.product_images.map((image) => ({
            id: image.id,
            url: image.image_path,
            isPrimary: image.is_primary,
          }));
        } else {
          console.error("Error fetching product details:", response);
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    },

    async submitForm() {
      const isValid = await this.$refs.formObserver.validate();
      if (!isValid) {
        return;
      }

      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("product_name", this.productName);
      formData.append("quantity", this.quantityInStock);
      formData.append("short_description", this.shortDescription);
      formData.append("long_description", this.longDescription);
      formData.append("selling_price", this.sellingPrice);
      formData.append("cost_price", this.costPrice);
      formData.append("date_added", this.dateAdded);
      formData.append("product_category_id", this.product_category_id);
      if (Array.isArray(this.warehouse_id)) {
        let warehouseIds = this.warehouse_id
          .map((warehouse) => warehouse.id.toString())
          .join(",");
        formData.append("warehouse_id", warehouseIds);
      } else {
        formData.append("warehouse_id", this.warehouse_id.toString());
      }
      if (this.addDiscount) {
        formData.append("discount", this.discount);
      }
      if (this.addExpiryDate) {
        formData.append("expiry_date", this.expiryDate);
      }
      if (this.addReturnPolicy) {
        formData.append("return_policy", this.returnPolicy);
      }
      this.imageUrls.forEach((image, index) => {
        if (image.file) {
          formData.append(`product_images[${index}]`, image.file);
        }
      });
      console.log("Delete images:", this.deleteImages);
      this.deleteImages.forEach((imageId, index) => {
        formData.append(`delete_images[${index}]`, imageId);
      });
      try {
        const response = await this.$http.post(
          `inventory/admin/products/${this.id}`,
          formData
        );
        if (response.data.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "CheckIcon",
              variant: "success",
              text: "Product updated successfully!",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "XIcon",
              variant: "danger",
              text: "Error updating product.",
            },
          });
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "XIcon",
            variant: "danger",
            text: "Error updating product.",
          },
        });
      }
    },

    onImageChange(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const url = URL.createObjectURL(file);
        this.imageUrls.push({ url, file });
      }
    },
    deleteImage(index) {
      const image = this.imageUrls[index];
      if (image.id) {
        this.deleteImages.push(image.id);
      }
      this.imageUrls.splice(index, 1);
      this.images.splice(index, 1);
    },
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>